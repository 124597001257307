import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";
import Layout from "../components/Layout";
import Contactform from "../components/ContactForm";

export default () => (
  <Layout>
    <SEO
      title="Commercial Boiler Servicing"
      description="Trust BBIS Heating for top-tier commercial boiler servicing in London. Our meticulous inspections and maintenance routines are designed to maximize the lifespan and efficiency of your heating systems, ensuring a comfortable environment for your business."
      keywords="central heating service london,central heating services london,london central heating services,boiler and central heating service
      ,boiler central heating service,central heating service providers,oil central heating service,central heating boiler service,gas central heating service,central
       heating service,central heating services,central heating systems london,gas central heating system installation,oil central heating system,gas central heating
        system,new central heating system,electric central heating systems,central heating system,"
    />
    <Jumbotron imgClassName="img1" />
    <div class="white-segment">
      <h1 class="heading-color">Commercial boiler servicing</h1>
      <p>
        Regularly servicing your commercial boiler is really important to ensure
        it is running as safely and efficiently as possible. Having your boiler
        serviced will highlight any possible problems that can be fixed before a
        breakdown occurs, and it’s back in use for the winter.
      </p>
      <p>
        You will also be prolonging the life of your boiler by having regular
        servicing, which will in the long run be more cost effective for your
        business rather than paying out for a new boiler.{" "}
      </p>
      <p>
        Your boilers warranty may be invalid if it isn’t serviced on an annually
        basis. It is also a good idea to have the boiler servicing carried out
        at the same time as the gas safety check.{" "}
      </p>
      <p>
        For a professional heating service contact us today on{" "}
        <strong>
          <a href="tel:+448002289800">0800 2289800</a>
        </strong>
      </p>
      <Contactform></Contactform>
    </div>
  </Layout>
);
