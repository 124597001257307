import React, { useState } from "react";
import { Form, Button, Col, InputGroup, Alert } from "react-bootstrap";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";

  export const ContactForm = () => {
    const { register, errors } = useForm();
  
    const [contactFormSuccess, changeContactFormSuccess] = useState(false);
    const [contactFormLoading, changeContactFormLoading] = useState(false);
    const [contactFormError, changeContactFormError] = useState(false);
  
    function sendEmail(e) {
      e.preventDefault();
      changeContactFormLoading(true);
      changeContactFormSuccess(false);
      changeContactFormError(false);
  
      emailjs
        .sendForm(
          "amazon_ses",
          "bbis_commercial_heating",
          e.target,
          "user_uB4PopJwbqrSoyPz8LPXg"
        )
        .then(
          result => {
            console.log(result.text);
            changeContactFormSuccess(true);
          },
          error => {
            console.log(error.text);
            changeContactFormError(true);
            changeContactFormLoading(false);
          }
        );
    }
  
    return (
      <div>
        <div className="segment white">
          <Form onSubmit={sendEmail}>
            {contactFormError && (
              <Alert variant="danger">
                <Alert.Heading>Uh oh...</Alert.Heading>
                <p>
                  There has been an error - try again later or give us a call{" "}
                  <a href="tel:+448002289800">here</a>.
                </p>
              </Alert>
            )}
            {contactFormSuccess && (
              <Alert variant="success">
                <Alert.Heading>Success</Alert.Heading>
                <p>
                  Thanks for contacting BBIS Commercial Heating LTD, we will respond to you as soon
                  as we can!
                </p>
              </Alert>
            )}
  
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="exampleForm.ControlInput1">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  id="from_name"
                  name="from_name"
                  type="text"
                  placeholder="John Smith"
                  required
                  ref={register({
                    required: "Name Required",
                    pattern: {
                      value: /^[A-Za-z\s']+$/,
                      message: "invalid name"
                    }
                  })}
                />
                <div className="errors">
                  {errors.full_name && errors.full_name.message}
                </div>
              </Form.Group>
            </Form.Row>
  
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="user_name">
                <Form.Label>Email address</Form.Label>
                <InputGroup>
                  <Form.Control
                    id="reply_to"
                    name="reply_to"
                    type="email"
                    placeholder="name@example.com"
                    required
                    ref={register({
                      required: "Email Required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "invalid email address"
                      }
                    })}
                  />
                </InputGroup>
                <div className="errors">
                  {errors.email && errors.email.message}
                </div>
              </Form.Group>
            </Form.Row>
  
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="user_number">
                <Form.Label>Contact Number</Form.Label>
  
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">+44</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    name="contact_number"
                    id="contact_number"
                    type="tel"
                    required
                    ref={register({
                      required: "Phone Number Required",
                      pattern: {
                        value: /^[\d]{9,12}$/i,
                        message: "invalid telephone number"
                      }
                    })}
                  />
                </InputGroup>
                <div className="errors">
                  {errors.telephone && errors.telephone.message}
                </div>
              </Form.Group>
            </Form.Row>
  
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="user_hcwh">
                <Form.Label>How can we help?</Form.Label>
                <Form.Control name="query" as="select" id="query">
                  <option value="Estimate / Quote">Estimate / Quote</option>
                  <option value="Existing Query">Existing Query</option>
                  <option value="Remeha Boiler Issue">Remeha Boiler Issue</option>
                  <option value="General Enquiry">General Enquiry</option>
                  <option value="Job Opening">Job Opening</option>
                </Form.Control>
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="user_name_hdyhau">
                <Form.Label>How did you hear about us?</Form.Label>
                <Form.Control as="select" name="channel" id="channel">
                  <option value="Friend / Family Member">
                    Friend / Family Member
                  </option>
                  <option>Web Search</option>
                  <option>Social Media</option>
                  <option>Online (Other)</option>
                  <option>Client Referral</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
  
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="user_number">
                <Form.Label>Additional Information</Form.Label>
  
                <InputGroup>
                  <Form.Control
                    as="textarea"
                    id="message_html"
                    name="message_html"
                    type="text"
                    rows="5"
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Form.Row>
  
            <Form.Row>
              <Form.Group controlId="exampleForm.Button">
                <Button type="submit" disabled={contactFormLoading}>
                  <i className="fa fa-paper-plane"></i> Submit
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      </div>
    );
  };

export default ContactForm;
